import styled from 'styled-components';

const StyledSellYourHomePage = styled.article`
	.masthead {
		@media (max-width: 768px) {
			background-position: 30% center;
		}
	}

	.accent-text {
		font-family: ${(p) => p.theme.fonts.secondary};
		font-size: 2.2rem;
		letter-spacing: 0.05em;
		color: ${(p) => p.theme.colors.primary};
		margin-top: 3.5rem;
	}

	.two-column-section {
		padding-top: 9rem;
		padding-bottom: 9rem;

		.grid {
			display: grid;
			grid-template-columns: 1.2fr 1fr;
			column-gap: 50px;

			.content-container {
				max-width: 44rem;

				.heading {
					margin-bottom: 0.5em;
				}
			}
		}

		@media (max-width: 999px) {
			padding-bottom: 3.5rem;

			.grid {
				grid-template-columns: 1fr;
				grid-template-rows: auto 1fr;
				row-gap: 32px;
			}
		}
	}

	.difference-section {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		padding-top: 8rem;
		padding-bottom: 5.5rem;

		h2 {
			text-align: center;
			margin-bottom: 3em;
		}

		.difference-cta-wrapper {
			margin-top: 4.2rem;
			text-align: center;

			.accent-text {
				margin-bottom: 1em;
			}

			.button {
				white-space: normal;
				line-height: 1.45;
				padding-top: 0.5em;
				padding-bottom: 0.5em;
				height: auto;
				color: ${(p) => p.theme.colors.lightest};
				background-color: ${(p) => p.theme.colors.primary};
				border-color: ${(p) => p.theme.colors.primary};

				&:hover {
					color: ${(p) => p.theme.colors.primary};
					background-color: rgba(255, 255, 255, 0);
				}
			}
		}

		@media (max-width: 499px) {
			h2 {
				margin-bottom: 1.5em;
			}
		}
	}

	.testimonials-section {
		padding-top: 10rem;
		padding-bottom: 15rem;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		@media (max-width: 768px) {
			padding-top: 5rem;
			padding-bottom: 10rem;
		}

		h2 {
			+ [class^='AccentLine'] {
				margin-bottom: 1.5rem;
				background-color: ${(props) => props.theme.colors.textGrey};

				+ .sub-head {
					font-family: ${(props) => props.theme.fonts.secondary};
					font-weight: ${(props) => props.theme.fonts.weights.regular};
					font-size: 2.5rem;
				}
			}
		}
	}

	.save-section {
		background-color: ${(props) => props.theme.colors.secondary};
		padding-top: 10rem;
		padding-bottom: 5rem;

		h2 {
			color: ${(props) => props.theme.colors.tertiary};
			font-size: 2rem;

			+ [class^='AccentLine'] {
				margin-bottom: 2rem;
				background-color: ${(props) => props.theme.colors.lightest};

				+ .sub-head {
					font-family: ${(props) => props.theme.fonts.secondary};
					font-size: 2.8rem;
					color: ${(p) => p.theme.colors.lightest};
					margin-bottom: 10rem;
				}
			}
		}

		[class^='SavingsSlider'] {
			margin-top: 4rem;
			margin-bottom: 6.5rem;

			.savings {
				p {
					span {
						&:nth-child(2) {
							color: ${(props) => props.theme.colors.lightest};
							background-color: ${(props) => props.theme.colors.primary};
						}
					}
				}
			}
		}

		.accent-text {
			color: #ffffff;
			margin-bottom: 0.75em;
		}

		.button {
			white-space: normal;
		}

		small {
			display: block;
			font-size: 1.6rem;
			color: ${(props) => props.theme.colors.textDarkerGrey};
			max-width: 700px;
			margin-top: 3rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
`;

export default StyledSellYourHomePage;
