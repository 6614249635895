import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import AccentLine from '../components/AccentLine';
import TestimonialsCarousel from '../components/carousels/TestimonialsCarousel';
import SavingsSlider from '../components/slider/SavingsSlider';
import Video from '../components/Video';
import { IconCards } from '../components/icon-cards';

import StyledSellYourHomePage from '../styles/pages/StyledSellYourHomePage';
import StyledIntro from '../styles/StyledIntro';
import theme from '../styles/theme/theme';

export const SellYourHomePageTemplate = ({
	intro,
	differenceSection,
	testimonialsBg,
}) => (
	<StyledSellYourHomePage>
		<StyledIntro className="section" color={theme.colors.primary}>
			<div className="container">
				<div className="grid two-columns">
					<div className="grid-column">
						<div className="intro-content-container">
							<h1 className="heading">Sell Your Home For A 1% Listing Commission</h1>
							<AccentLine color={theme.colors.primary} />
							<div className="intro-image-wrapper mobile">
								<Img fluid={intro.image.childImageSharp.fluid} />
							</div>
							<p className="intro-text">{intro.text}</p>
							<p className="accent-text">{intro.accent_text}</p>
							<Link className="button" to="/contact">
								Book Your Free Home Evaluation Today
							</Link>
						</div>
					</div>
					<div className="grid-column">
						<div className="intro-image-wrapper desktop">
							<Img fluid={intro.image.childImageSharp.fluid} />
						</div>
					</div>
				</div>
			</div>
		</StyledIntro>
		<section className="section two-column-section">
			<div className="container">
				<div className="grid">
					<div className="grid-column">
						<Video
							videoSrcURL={
								'https://player.vimeo.com/video/551632209?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
							}
							videoTitle={'Parker Coulter Realty | List for 1%'}
						/>
					</div>
					<div className="grid-column">
						<div className="content-container">
							<h2 className="heading heading-style-2">
								Full Service at a Fraction of the Cost
							</h2>
							<p>
								Parker Coulter Real Estate offers personalized service 24/7, and
								will list your home for a low 1% commission. You’ll save money
								while still receiving all the services you need to sell your
								home quickly and efficiently — from professional hi-res and
								aerial photography to extensive exposure on local and Toronto
								real estate boards. It’s unbeatable value and it’s what sets us
								apart. You’ll be provided with all the support and guidance you
								need to make your next move.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section
			className="section difference-section"
			style={{
				backgroundImage: `url(${differenceSection.section_bg.publicURL})`,
			}}
		>
			<div className="container">
				<div className="columns">
					<div className="column">
						<h2>{differenceSection.heading}</h2>
					</div>
				</div>
				<div className="columns">
					<div className="column">
						<IconCards columns={4} cards={differenceSection.icon_cards} />
						<div className="difference-cta-wrapper">
							<p className="accent-text">Ready to sell your home?</p>
							<Link className="button" to="/contact">
								Book Your Free Home Evaluation Today
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className="section save-section">
			<div className="container">
				<div className="columns is-centered">
					<div className="column has-text-centered">
						<h2>Save When You Sell with Us</h2>
						<AccentLine align="center" />
						<p className="sub-head">Slide to select your home’s value</p>
						<SavingsSlider />
						<div className="difference-cta-wrapper">
							<p className="accent-text">So what are you waiting for?</p>
							<Link className="button button-1" to="/contact">
								Contact us for a complimentary home evaluation today.
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section
			className="section testimonials-section"
			style={{
				backgroundImage: `url(${testimonialsBg.childImageSharp.fluid.src})`,
			}}
		>
			<div className="container">
				<div className="columns">
					<div className="column has-text-centered">
						<h2>Testimonials</h2>
						<AccentLine align="center" />
						<p className="sub-head">What our clients have to say</p>
					</div>
				</div>
				<TestimonialsCarousel />
			</div>
		</section>
	</StyledSellYourHomePage>
);

SellYourHomePageTemplate.propTypes = {
	title: PropTypes.string,
};

const SellYourHomePage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout page={`sell`}>
			<SEO
				title={frontmatter.title}
				description="Sell Your Home For 1% Listing Commissions With Parker Coulter Realty. Full Service Real Estate Brokerage Offering Great Service, Premium Marketing and Commission Savings."
			/>
			<SellYourHomePageTemplate
				title={frontmatter.title}
				intro={frontmatter.intro}
				differenceSection={frontmatter.difference_section}
				testimonialsBg={frontmatter.testimonials_bg}
			/>
		</Layout>
	);
};

SellYourHomePage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default SellYourHomePage;

export const sellYourHomePageQuery = graphql`
	query SellYourHomePage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				intro {
					heading
					text
					accent_text
					image {
						childImageSharp {
							fluid(maxWidth: 1920) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				difference_section {
					heading
					icon_cards {
						icon {
							publicURL
						}
						text
					}
					section_bg {
						publicURL
					}
				}
				testimonials_bg {
					childImageSharp {
						fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;
