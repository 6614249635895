import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
	position: relative;
	padding-top: 56.25%;

	iframe {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
	<StyledDiv className="video">
		<iframe
			src={videoSrcURL}
			title={videoTitle}
			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			frameBorder="0"
			webkitallowfullscreen="true"
			mozallowfullscreen="true"
			allowFullScreen
		/>
	</StyledDiv>
);

export default Video;
